import loadable from '@loadable/component';

const getComponentByType = (type) => {
  switch (type) {
    case 'ContentfulFeaturedArticlesModule':
      return loadable(() => import('src/components/FeaturedArticlesModule'));

    case 'ContentfulCardStackModule':
      return loadable(() => import('src/components/CardStackModule'));

    case 'ContentfulColumnModule':
      return loadable(() => import('src/components/ColumnModule'));

    case 'ContentfulCustomModule':
      return loadable(() => import('src/components/CustomModule'));

    case 'ContentfulLogoListModule':
      return loadable(() => import('src/components/LogoListModule'));

    case 'ContentfulSliderModule':
      return loadable(() => import('src/components/SliderModule'));

    case 'ContentfulTextBlock':
      return loadable(() => import('src/components/TextBlock'));

    case 'ContentfulThreeUpModule':
      return loadable(() => import('src/components/ThreeUpModule'));

    case 'ContentfulNewTwoUpModule':
      return loadable(() => import('src/components/NewTwoUpModule'));

    case 'ContentfulRevealModule':
      return loadable(() => import('src/components/RevealModule'));

    case 'ContentfulZoomModule':
      return loadable(() => import('src/components/ZoomModule'));

    case 'ContentfulCaptionsModule':
      return loadable(() => import('src/components/CaptionsModule'));

    case 'ContentfulGridModule':
      return loadable(() => import('src/components/GridModule'));

    case 'ContentfulStatsModule':
      return loadable(() => import('src/components/StatsModule'));

    case 'ContentfulAccordionModule':
      return loadable(() => import('src/components/AccordionModule'));

    case 'ContentfulWebinarModule':
      return loadable(() => import('src/components/WebinarModule'));

    default:
      return null;
  }
};

export default getComponentByType;
