import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'src/components/Layout';
import Form from 'src/components/Form';
import Text from 'src/components/Text';
import TextStack from 'src/components/TextStack';
import RichText from 'src/components/RichText';
import BackgroundImg from 'gatsby-background-image';
import getComponentByType from 'src/utils/getComponentByType';

export default function Event({ data }) {
  const { title, form, description, heroImage, footerModules } = data.event;

  const date = new Date(data.event.date);
  const formattedDate = `${date.toLocaleString('en-US', {
    timeZone: 'UTC',
  })} UTC`;

  const fluid = [
    'linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)',
    heroImage.fluid,
  ];

  return (
    <Layout hasFixedNav={true}>
      <BackgroundImg
        fluid={fluid}
        css={({ theme }) => ({
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: '1fr 1fr',
          minHeight: '100vh',
          color: 'white',
          marginTop: 'calc(var(--header-height) * -1)',
          padding: `80px ${theme.layout.pageMargin}`,
        })}
      >
        <TextStack>
          <Text variant="heading.s">{formattedDate}</Text>
          <Text variant="heading.xl">{title}</Text>
          <RichText {...description} />
        </TextStack>
        <Form {...form} />
      </BackgroundImg>
      {footerModules &&
        footerModules.map((footerModule) => {
          const Component = getComponentByType(footerModule.__typename);
          return (
            Component && <Component key={footerModule.id} {...footerModule} />
          );
        })}
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    event: contentfulEvent(slug: { eq: $slug }) {
      title
      date
      form {
        ...FormFragment
      }
      description {
        json
      }
      heroImage {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      footerModules {
        ... on ContentfulSliderModule {
          ...SliderModuleFragment
        }
        ... on ContentfulThreeUpModule {
          ...ThreeUpModuleFragment
        }
      }
    }
  }
`;
